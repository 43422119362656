import React from "react";

const NotFound = () => {
    return ( 
        <center>
            <br />
            <h1>404</h1>
            <h3>صفحه مورد نظر پیدا نشد</h3>
        </center>
     );
}
 
export default NotFound;